import { styled } from 'styled-components'
import Box from '@mui/material/Box'
import theme from '../../../../Theme'
import { IconButton } from '@mui/material'

export const StyledBotMessageWrapper = styled(Box) <{ isMobile: boolean }>`
  width: 100%;
  max-width: ${(props) => props.isMobile ? '100%' : '100%'};
  justify-self: flex-start;
`

export const StyledBotMessage = styled(Box)`
  display: flex;
  flex-flow: wrap;
  position: relative;
  width: 100%;
  background: #EFEFEF;
  padding: ${theme.spacing(3)};
  border-radius: ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} ${theme.spacing(2)} 
`

export const StyledFeedbackWrapper = styled(Box)`
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #D3D8E0;
  margin-top: ${theme.spacing(1)};
  border-radius: ${theme.spacing(2)};
  width: 120px;
  height: 32px;
  box-shadow: 0px 4px 4px 0px #00000040;
`

export const StyledReactionWrapper = styled(Box)`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`

export const StyledTimeStamp = styled(Box)`
  margin-top: ${theme.spacing(1.25)};
  margin-bottom: ${theme.spacing(4)};
  font-size: ${theme.spacing(2.5)};
  font-weight: bold;
  text-align: end;
`

export const StyledIconButton = styled(IconButton)`
  height: 100%;
  display: flex; 
  align-items: center; 
  justify-content: center;
  color: #2E2E2E !important;  

  &:hover {
    color: #007CF9 !important; 
  }
`

export const StyledBotIcon = styled(Box) <{ customBackground: string }>`
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: ${(props) => (props.customBackground !== '') ? props.customBackground : '#CF3F7C'};
  margin-right: 6px;
  text-align: center;
  padding-top: 2px;
`

export const StyledMarkdownSection = styled(Box)`
  width: calc(100% - 38px);
  margin-top: 4px;
`
