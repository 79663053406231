import { styled } from 'styled-components'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import theme from '../../../Theme'

export const StyledHeader = styled(Box) <{ isMobile: boolean, isHeaderColor: string | null }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${`${theme.spacing(14.5)}`};
  border-radius: ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(0)} ${theme.spacing(0)};
  background: ${(props) => props.isHeaderColor ?? `linear-gradient(to bottom, ${theme.colors.primary.main} 0%, ${theme.colors.primary.secondary} 100%)`};
`

export const StyledButtonBox = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%
`

export const StyledIconButton = styled(IconButton)`
  height: 100%;
  display: flex; 
  align-items: center; 
  justify-content: center;
  color: white !important;  
`

export const StyledMesssageImageWrapper = styled(Box)`
  flex: 1;
  text-align: center;
`

export const StyledMesssageImage = styled.img<{ isMobile: boolean }>`
  height: ${`${theme.spacing(10.75)}`};
`
