import { styled } from 'styled-components'
import theme from '../../../Theme'
import Box from '@mui/material/Box'

export const StyledChatMessagesWrapper = styled(Box) <{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: ${(props) => props.isMobile ? 'calc(100% - 58px)' : 'calc(100%)'};
`

export const StyledMessageList = styled(Box) <{ isMobile: boolean }>`
padding: 0px 10px 0px 10px;
margin: ${(props) => props.isMobile ? `${theme.spacing(3)}` : `${theme.spacing(4)} ${theme.spacing(6)}`} ;
flex-grow: 1;
overflow-y: auto;
scrollbar-width: thin;
scrollbar-color: transparent transparent;
transition: scrollbar-color 0.5s;
  &:hover {
    scrollbar-color: #D6D6D6 transparent;
  }
  &::-webkit-scrollbar {
  width: 6px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
  &::-webkit-scrollbar-thumb {
  background-color: transparent;
}
  &::-webkit-scrollbar-track {
  background-color: transparent;
}
`

export const StyledChatMessageFooter = styled(Box) <{ isMobile: boolean }>`
justify-content: center;
display: flex;
flex-flow: wrap;
margin-bottom: ${(props) => props.isMobile ? '' : '32px'}
`

export const StyledInputAndButtonWrapper = styled(Box) <{ isMobile: boolean }>`
width: 100%;
display: flex;
justify-content: space-between;
gap: ${theme.spacing(3)};
margin: ${(props) => props.isMobile ? `${theme.spacing(0)} ${theme.spacing(3)} ${theme.spacing(0)} ${theme.spacing(3)}` : `${theme.spacing(0)} ${theme.spacing(6)} ${theme.spacing(0)} ${theme.spacing(6)}`};
`

export const StyledInputWrapper = styled(Box) <{ isMobile: boolean }>`
display: flex;
width: inherit;
justify-content: space-between;
border: 1px solid ${theme.colors.border.light};
box-shadow: rgba(0, 0, 0, 0.25) 1.95px 1.95px 2.6px;
background: white;
border-radius: ${theme.spacing(1.5)};
`

export const StyledInput = styled.input <{ isMobile: boolean, isfeedbacksubmit: boolean }>`
  &::placeholder {
  color: ${props => (props.isfeedbacksubmit ? 'black' : '')};
  @media (max-width: 768px) {
    font-size: ${theme.spacing(4.5)};
  }
}
color: black;
border: none;
outline: none;
width: 100%;
margin: ${`${theme.spacing(2.25)}`};
font-size: ${`${theme.spacing(4.5)}`};
`

export const StyledImage = styled.img <{ isMobile: boolean }>`
width: ${(props) => props.isMobile ? `${theme.spacing(6)}` : `${theme.spacing(7)}`};
height: ${(props) => props.isMobile ? `${theme.spacing(7)}` : `${theme.spacing(8)}`};
margin: ${`${theme.spacing(2.25)} ${theme.spacing(2.25)} ${theme.spacing(2.25)} ${theme.spacing(0)}`};
&:hover {
  cursor: pointer;
}
`

export const StyledLoadingBox = styled(Box) <{ isMobile: boolean }>`
width: ${(props) => props.isMobile ? `${theme.spacing(7)}` : `${theme.spacing(8)}`};
height: ${(props) => props.isMobile ? `${theme.spacing(7)}` : `${theme.spacing(8)}`};
margin: ${`${theme.spacing(2.25)} ${theme.spacing(2.25)} ${theme.spacing(2.25)} ${theme.spacing(0)}`};
`

export const StyledText = styled.text`
font-size: 10px;
width: 100%;
text-align: center;
margin: 8px;
color: grey;
font-style: italic;
`

export const StyledTextWrapper = styled(Box)`
display: flex;
`

export const StyledLoadingIndicator = styled(Box)`
position: relative;
width: ${theme.spacing(2.5)};
height: ${theme.spacing(2.5)};
border-radius: ${theme.spacing(1.25)};
background-color: ${theme.colors.border.dark};
color: ${theme.colors.border.dark};
animation: dot-flashing 1s infinite linear alternate;
animation-delay: 0.5s;
margin-left: ${theme.spacing(5)};
margin-bottom: ${theme.spacing(5)};

&::before, &::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
&::before {
  left: -15px;
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  border-radius: ${theme.spacing(1.25)};
  background-color: ${theme.colors.border.dark};
  color: ${theme.colors.border.dark};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
&::after {
  left: ${theme.spacing(3.75)};
  width: ${theme.spacing(2.5)};
  height: ${theme.spacing(2.5)};
  border-radius: ${theme.spacing(1.25)};
  background-color: ${theme.colors.border.dark};
  color: ${theme.colors.border.dark};
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: ${theme.colors.primary.main};
}
50%, 100% {
  background-color: ${theme.colors.border.dark};
  }
}
`

export const StyledNextQuestions = styled(Box)`
  display: flex;
  justify-content: flex-start;
  flex-flow: wrap;
  gap: 8px;
  width: 100%;
  margin-top: 4px;
`
