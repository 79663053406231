import React, { useEffect, useState } from 'react'
import BepLogo from '../../../../assets/bepLogo.png'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SampleManufacturerQuestions, SampleOperatorQuestions } from '../../../../consts/SampleQuestions'
import SampleQuestion from './../SampleQuestion'
import { StyledInitialScreen, StyledBackgroundImage } from './styled'
import getOrientation from '../../../../utils/getOrientation'
import getForcedMobile from '../../../../utils/forceMobile'
import { parsePlatformId } from '../../../../utils/parsePlatformId'

interface InitialScreenProps {
  handleSampleQuestion: (arg: string) => void
}

const InitialScreen: React.FC<InitialScreenProps> = ({ handleSampleQuestion }): JSX.Element => {
  const theme = useTheme()
  const isPortrait = getOrientation()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const platformId = parsePlatformId(localStorage.getItem('platform_id') ?? '')
  const userQuestions = localStorage.getItem('sample_questions') ?? ''
  const [splitPlatformId, setSplitPlatformId] = useState<string[]>([])
  const [specifiedQuestions, setSpecifiedQuestions] = useState<Array<{ text: string }>>([])
  const logo = (): string => {
    const platformLogo = localStorage.getItem('logo') === '' ? null : localStorage.getItem('logo')
    if (platformLogo !== null) {
      return platformLogo
    } else {
      return BepLogo
    }
  }

  const chooseQuestions = (): void => {
    if (userQuestions !== '') {
      setSpecifiedQuestions(JSON.parse(userQuestions))
    } else {
      if (splitPlatformId[0] === 'MA') {
        setSpecifiedQuestions(SampleManufacturerQuestions)
      } else if (splitPlatformId[0] === 'CL') {
        setSpecifiedQuestions(SampleOperatorQuestions)
      } else {
        setSpecifiedQuestions([])
      }
    }
  }

  useEffect(() => {
    if (platformId != null) {
      setSplitPlatformId(platformId[0].split('-'))
    }
  }, [])

  useEffect(() => {
    chooseQuestions()
  }, [splitPlatformId])

  return (
    <StyledInitialScreen isMobile={isMobile || isPortrait || forcedMobile}>
      <StyledBackgroundImage>
        <img src={logo()} alt='BG' height={isMobile ? '81px' : '162px'} />
      </StyledBackgroundImage>
      <Grid container spacing={isMobile || isPortrait || forcedMobile ? '20px' : '12px'} style={{ marginBottom: '24px' }}>
        {specifiedQuestions?.map((question, index) => (
          <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
            <SampleQuestion
              key={index}
              text={question.text}
              sampleQuestionClick={() => { handleSampleQuestion(question.text) }}
            />
          </Grid>
        ))}
      </Grid>
    </StyledInitialScreen>
  )
}

export default InitialScreen
