import React, { useEffect, useState } from 'react'
import BepIconWhite from '../../../assets/bepIconWhite.png'
import {
  StyledHeader,
  StyledIconButton,
  StyledMesssageImage,
  StyledMesssageImageWrapper,
  StyledButtonBox
} from './styled'
import { AddCommentOutlined, OpenInFullOutlined, CloseOutlined, CloseFullscreenOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import getForcedMobile from '../../../utils/forceMobile'

interface ChatConversationHeaderProps {
  onOpenNewChat: () => void
}

const ChatBotScreenHeader: React.FC<ChatConversationHeaderProps> = ({ onOpenNewChat }): JSX.Element => {
  const theme = useTheme()
  const forcedMobile = getForcedMobile()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const [showIcon, setShowIcon] = useState(true)
  const [chatbotExpanded, setChatbotExpanded] = useState(false)
  const headerColor = localStorage.getItem('headerColor') === '' ? null : localStorage.getItem('headerColor')
  const hideExpandIcon = localStorage.getItem('hide_expand_icon') === 'true'
  console.log(hideExpandIcon)

  useEffect(() => {
    if (localStorage.getItem('showHeaderLogo') === 'false') {
      setShowIcon(false)
    }
  }, [])

  const onChangeChatbotSize = (): void => {
    chatbotExpanded ? setChatbotExpanded(false) : setChatbotExpanded(true)
    chatbotExpanded ? parent.postMessage('shrinkChatbotWindow', '*') : parent.postMessage('expandChatbotWindow', '*')
  }

  const onCloseChat = (): void => {
    parent.postMessage('closeChatbotWindow', '*')
  }

  return (
    <StyledHeader isMobile={isMobile || forcedMobile} isHeaderColor={headerColor}>
      <StyledMesssageImageWrapper>
        {showIcon &&
        <StyledMesssageImage
          isMobile={isMobile || forcedMobile}
          src={BepIconWhite}
          alt='Message Icon'
        />
        }
      </StyledMesssageImageWrapper>
      <StyledButtonBox>
        <StyledIconButton size="large" title='Start New Chat' onClick={onOpenNewChat}>
          <AddCommentOutlined fontSize="medium"/>
        </StyledIconButton>
        {chatbotExpanded && !hideExpandIcon &&
          <StyledIconButton size="large" title='Minimize Chat Window' onClick={() => { onChangeChatbotSize() }}>
            <CloseFullscreenOutlined fontSize="medium"/>
          </StyledIconButton>
        }
        {!chatbotExpanded && !hideExpandIcon &&
          <StyledIconButton size="large" title='Expand Chat Window' onClick={() => { onChangeChatbotSize() }}>
            <OpenInFullOutlined fontSize="medium"/>
          </StyledIconButton>
        }
        <StyledIconButton size="large" title='Close Chat Window' onClick={() => { onCloseChat() }}>
          <CloseOutlined fontSize="medium"/>
        </StyledIconButton>
      </StyledButtonBox>
    </StyledHeader>
  )
}

export default ChatBotScreenHeader
