import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import LoginFeature from '../../features/LoginFeature'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../consts/Routes'

const Login: React.FC = (): JSX.Element => {
  const navigate = useNavigate()
  const { sessionId, plid } = useParams()
  const [connectionMade, setConnectionMade] = useState(false)
  const [dataReceived, setDataReceived] = useState(false)

  window.addEventListener('message', (event) => {
    if (event.data === 'chatbotConnectionMade' && !connectionMade) {
      setConnectionMade(true)
    }
    if (!dataReceived && connectionMade && (event.data.token !== null || event.data.token !== undefined)) {
      if (event.data.forceMobileView !== null) {
        localStorage.setItem('forceMobileView', event.data.forceMobileView)
      }
      localStorage.setItem('token', event.data.authToken)
      localStorage.setItem('session_id', `"${event.data.sessionId}"`)
      localStorage.setItem('platform_id', event.data.platformId)
      localStorage.setItem('internal_user', event.data.internalUser)
      localStorage.setItem('platform', event.data.platform)
      localStorage.setItem('user_name', event.data.user_name)
      localStorage.setItem('user_email', event.data.user_email)
      localStorage.setItem('headerColor', event.data.headerColor ?? '')
      localStorage.setItem('logo', event.data.logo ?? '')
      localStorage.setItem('showHeaderLogo', event.data.showHeaderLogo ?? '')
      localStorage.setItem('question', event.data.marketingQuestion ?? '')
      localStorage.setItem('sample_questions', event.data.sampleQuestions ?? '')
      localStorage.setItem('erni_icon', event.data.erniIcon ?? '')
      localStorage.setItem('erni_icon_background', event.data.erniIconBackground ?? '')
      localStorage.setItem('hide_expand_icon', event.data.hideExpandIcon ?? '')
      setDataReceived(true)
      navigate(ROUTES.HOME)
    }
  })

  const broadcastChatbotReady = (): void => {
    parent.postMessage('chatbotHandshake', '*')
  }

  const broadcastReadyForData = (): void => {
    parent.postMessage('chatbotReadyForData', '*')
  }

  useEffect(() => {
    if (!connectionMade) {
      broadcastChatbotReady()
    }
    if (connectionMade) {
      broadcastReadyForData()
    }
  }, [connectionMade])

  useEffect(() => {
    if (sessionId !== undefined) {
      localStorage.setItem('token', sessionId)
      localStorage.setItem('session_id', `"${uuidv4()}"`)
      if (plid !== undefined) {
        localStorage.setItem('platform_id', plid)
        localStorage.setItem('internal_user', 'false')
        localStorage.setItem('platform', 'summit')
      }
    }
  }, [])

  return (
    <>
      <LoginFeature />
    </>
  )
}

export default Login
